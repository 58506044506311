import {io} from 'socket.io-client'
//@ts-ignore
import {v4 as uuid} from 'uuid'
import React, {useEffect, useState} from 'react'
import {UserModel} from '../../models/user.model'
import {CModalTwo} from '../mui/ModalTwo'
import {Welcome} from './login-wallet-connect-steps/welcome'
import {SignupQuestion} from './login-wallet-connect-steps/signup-question'
import {Signup} from './login-wallet-connect-steps/signup'
import {Loading} from './login-wallet-connect-steps/loading'
import WalletIframe from './login-wallet-connect-steps/walletIframe'
import {isDesktop} from '../../utils/detect-screen'
import {ButtonTwoStyled} from "../syledComponents/Buttons.styled";
import {loginCheck} from "../../api/personia/auth.apis";
import {ApiErrorData} from "../../api/personia/http.api";

interface Props {
    color?: string
    textColor?: string
    size?: string
    ref?: any
    background?: string
    startIcon?: string
    loadingColor?: string
    fullWidth?: boolean
    backgroundHover?: string
    onClick?: () => void
    text?: string
}


export default function LoginWalletConnect(props: Props) {
    const {text} = props
    const [loginState, setLoginState] = useState<boolean>(false)
    const [randomUuid, setUuid] = useState<string>('')
    const [user, setUser] = useState<UserModel>()
    const [loading, setLoading] = useState(false)
    const [loginStep, setLoginStep] = useState<
        | 'welcome'
        | 'signup-question'
        | 'signup'
        | 'none'
        | 'loading'
        | 'walletIframe'
        | 'welcome-final'
    >('loading')

    const socket = io(process.env.REACT_APP_API_BASE_URL ?? 'https://np.allostasis.io')

    const [newLogin, setNewLogin] = useState(false)
    const [wallet, setWallet] = useState<string>('')
    const [did, setDid] = useState<string>('')
    const [tempUser, setTempUser] = useState<UserModel>()

    function onConnect() {
        socket.emit('join', {randomString: 'login-' + randomUuid})
    }

    function onDisconnect() {
        setLoginStep('none')
        setLoading(false)
        setLoginState(false)
        socket.disconnect()
    }

    function onLogin(value: any) {
        if (value.session) {
            setLoginStep('loading')
            localStorage.setItem('token', value.session)
            setLoading(true)
            loginCheck()
                .then(result => {
                    setDid(result.data.did.pkh)
                    setWallet(result.data.wallet)
                    if (result.data.user) {
                        setUser(result.data.user)
                        setLoginStep('welcome')
                        setLoading(false)
                        socket.emit('disconnect', {})
                    } else {
                        setLoginStep('signup-question')
                    }
                })
                .catch((error: ApiErrorData) => {
                    setLoading(false)
                })
        }
    }

    function onJoinEvent(value: any) {
        console.log('join event', value)
    }

    useEffect(() => {
        if (newLogin || loginState) {
            const code = uuid()
            setUuid(code)
        }
    }, [newLogin, loginState])

    useEffect(() => {
        if ((newLogin || loginState) && randomUuid !== '') {
            setNewLogin(false)
            setLoginStep('walletIframe')

            socket.on('connect', onConnect)
            socket.on('disconnect', onDisconnect)
            socket.on('join', onJoinEvent)
            socket.on('walletLogin', onLogin)

            return () => {
                socket.off('connect', onConnect)
                socket.off('disconnect', onDisconnect)
                socket.off('join', onJoinEvent)
                socket.off('walletLogin', onLogin)
            }
        }
    }, [newLogin, loginState, randomUuid])

    const startLogin = () => {
        setLoginStep('loading')
        setLoginState(true)
    }

    const closeLoginModal = () => {
        setLoginStep('none')
        setLoading(false)
        setLoginState(false)
    }

    return (
        <>
            {loginState && randomUuid !== '' && (
                <CModalTwo
                    open={loginState}
                    onClose={closeLoginModal}
                    title="login modal"
                    width={loginStep ? (loginStep === 'walletIframe' ? '755' : (loginStep === 'signup' ? '578' : '400')) : '755'}
                    background={isDesktop() ? '#0D0D0D' : '#0D0D0D'}
                >
                    {loginStep === 'walletIframe' && (
                        <WalletIframe randomString={randomUuid}/>
                    )}
                    {loginStep === 'loading' && <Loading/>}
                    {loginStep === 'welcome' && (
                        <Welcome
                            loading={loading}
                            user={user}
                            onClose={closeLoginModal}
                            wallet={wallet}
                            did={did}
                        />
                    )}
                    {loginStep === 'signup-question' && (
                        <SignupQuestion
                            loading={loading}
                            onClose={closeLoginModal}
                            setLoginStep={setLoginStep}
                            setNewLogin={setNewLogin}
                        />
                    )}
                    {loginStep === 'signup' && (
                        <Signup
                            wallet={wallet}
                            did={did}
                            setTempUser={setTempUser}
                            setLoginStep={setLoginStep}
                        />
                    )}
                    {loginStep === 'welcome-final' && (
                        <Welcome
                            loading={loading}
                            user={user}
                            onClose={closeLoginModal}
                            wallet={wallet}
                            did={did}
                        />
                    )}
                    {loginStep === 'none' && (
                        <Loading onClose={closeLoginModal}/>
                    )}
                </CModalTwo>
            )}

            <ButtonTwoStyled onClick={startLogin}>
                <a className={"para3"}>
                    {text}
                </a>
            </ButtonTwoStyled>
        </>

    )
}