export interface HairsListType {
  code: string;
  items: Array<{
    name: string;
    image: string;
    colorCode: string;
  }>;
}

export const hairsList: Array<HairsListType> = [
  {
    code: "B2A190",
    items: [
      {
        name: "blond_woman_classic_hair_B2A190.glb",
        image: require("../../../../assets/images/hairs/blond_woman_classic_hair_B2A190.png"),
        colorCode: "B2A190",
      },
      {
        name: "light_blond_woman_classic_hair_B2A190.glb",
        image: require("../../../../assets/images/hairs/light_blond_woman_classic_hair_B2A190.png"),
        colorCode: "B2A190",
      },
      {
        name: "normal_man_hair.glb",
        image: require("../../../../assets/images/hairs/normal_man_hair.png"),
        colorCode: "B2A190",
      },
    ],
  },
  {
    code: "4FAEA8",
    items: [
      {
        name: "blue_woman_classic_hair_4FAEA8.glb",
        image: require("../../../../assets/images/hairs/blue_woman_classic_hair_4FAEA8.png"),
        colorCode: "4FAEA8",
      },
      {
        name: "woman_bob_blue_4FAEA8.glb",
        image: require("../../../../assets/images/hairs/woman_bob_blue_4FAEA8.png"),
        colorCode: "4FAEA8",
      },
    ],
  },
  {
    code: "836843",
    items: [
      {
        name: "classic_man_hair_light_836843.glb",
        image: require("../../../../assets/images/hairs/classic_man_hair_light_836843.png"),
        colorCode: "836843",
      },
    ],
  },
  {
    code: "382D21",
    items: [
      {
        name: "curl_man_brown_382D21.glb",
        image: require("../../../../assets/images/hairs/curl_man_light_C7C1A1.png"),
        colorCode: "C7C1A1",
      },
    ],
  },
  {
    code: "AE0500",
    items: [
      {
        name: "woman_hair_bob_red_AE0500.glb",
        image: require("../../../../assets/images/hairs/woman_hair_bob_red_AE0500.png"),
        colorCode: "B2A190",
      },
      {
        name: "woman_hair_bob_red_AE0500.glb",
        image: require("../../../../assets/images/hairs/woman_hair_bob_red_AE0500.png"),
        colorCode: "AE0500",
      },
    ],
  },
  {
    code: "2E1A10",
    items: [
      {
        name: "woman_bob_dark_2E1A10.glb",
        image: require("../../../../assets/images/hairs/woman_bob_dark_2E1A10.png"),
        colorCode: "2E1A10",
      },
    ],
  },
  {
    code: "9C7C5A",
    items: [
      {
        name: "woman_bob_light_9C7C5A.glb",
        image: require("../../../../assets/images/hairs/woman_bob_light_9C7C5A.png"),
        colorCode: "9C7C5A",
      },
    ],
  },
  {
    code: "1C1914",
    items: [
      {
        name: "woman_classic_hair_1C1914.glb",
        image: require("../../../../assets/images/hairs/woman_classic_hair_1C1914.png"),
        colorCode: "1C1914",
      },
    ],
  },
  {
    code: "32231B",
    items: [
      {
        name: "woman_classic_hair_brown_32231B.glb",
        image: require("../../../../assets/images/hairs/woman_classic_hair_brown_32231B.png"),
        colorCode: "32231B",
      },
    ],
  },
  {
    code: "BE9B94",
    items: [
      {
        name: "woman_hair_bob_highlight_BE9B94.glb",
        image: require("../../../../assets/images/hairs/woman_hair_bob_highlight_BE9B94.png"),
        colorCode: "BE9B94",
      },
    ],
  },
  {
    code: "5020AE",
    items: [
      {
        name: "woman_hair_bob_Violet_5020AE.glb",
        image: require("../../../../assets/images/hairs/woman_hair_bob_Violet_5020AE.png"),
        colorCode: "5020AE",
      },
    ],
  },
];
